.alt {
    background: #eae0c9;
}

.ant-layout-content > div {
    padding: 11px !important;
    width: 95% !important;
}

.ant-page-header.ant-page-header-compact{
    padding: 16px 4px !important;
}

.centered {
    text-align: center;
}

.uploader {
    height: 50vh !important;
}

#calficacionesPDF .alt{
    background: #eeeeee;
}

#calficacionesPDF *{
    color: #000000;
}
