.layout {
    background: radial-gradient(50% 50% at 50% 50%, #F0C051 0%, #A47F28 100%);
    background-size: "cover";
  }

  .container {
    max-width: 408px;
    margin: auto;
  }

  .title {
    text-align: center;
    color: #626262;
    font-size: 30px;
    letter-spacing: -0.04em;
  }

  .imageContainer {
    display: flex;
    flex: 0.5;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .imageContainer img {
      background-color: rgba(250, 250, 250, 1);
      border: solid 1px rgba(195, 195, 195, 0.7);
      border-radius: 0.5rem;
      box-shadow: 4px 3px 7px 2px #255c9b;
      max-width: 110%;
      padding: 0.3rem;
  }
